import Layout from '@/components/Layout'
import Layout2 from '@/components/Layout2'
/**
 * 基础路由
 * @type { *[] }
 */
let isPhone=function(){
   let userAgentInfo = navigator.userAgent;
   let Agent=['Android','iPhone','SymbianOS','Windows Phone','iPad','iPod']
   let flag=true;
   for (let index = 0; index < Agent.length; index++) {
    if (userAgentInfo.indexOf(Agent[index])>-1) {
      flag=false;
       break;
    }
   }
   return flag;
}
let isPc=isPhone();
console.log(isPc,98888888888888888)
export const constantRouterMap = [
  {
    path: '/',
    name: 'index',
    component:!isPc?Layout2:Layout,
    redirect:"/index",
    key: '1',
    icon: () => h(PieChartOutlined),
    label: 'Option 1',
    children: [
      {
        path: '/index',
        component: () => !isPc?import('@/views/home/Index_h5'):import('@/views/home/Index'),
        hidden: true
      },
      {
        path: '/product',
        redirect:"/wms",
        hidden: true,
        children:[
          {
            path: '/product_h5',
            component: () => import('@/views/product/Index_h5'),
            hidden: true
          },
          {
            path: '/ems',
            component: () => !isPc ? import('@/views/product/ems_h5') : import('@/views/product/ems'),
            hidden: true
          },
          {
            path: '/building',
            component: () => !isPc ? import('@/views/product/building_h5') : import('@/views/product/building'),
            hidden: true
          },
          {
            path: '/cloud',
            component: () => !isPc ?  import('@/views/product/cloud_h5') : import('@/views/product/cloud'),
            hidden: true
          },
          {
            path: '/cloudServe',
            component: () => !isPc ? import('@/views/product/cloudServe_h5') : import('@/views/product/cloudServe'),
            hidden: true
          },
                // 光伏
          {
            path: '/cloudpv',
            component: () => !isPc ? import('@/views/product/cloudpv_h5') : import('@/views/product/cloudpv'),
            hidden: true
          },
          // 零碳园区
          {
            path: '/carbonPark',
            component: () => !isPc ? import('@/views/product/carbonPark_h5') : import('@/views/product/carbonPark'),
            hidden: true
          },
            // 储能舱/储能柜
            {
              path: '/energyStorage',
              component: () => !isPc ? import('@/views/product/energyStorage_h5') : import('@/views/product/energyStorage'),
              hidden: true
            },
              // 充电站运营
            {
                path: '/chargingStation',
                component: () => !isPc ? import('@/views/product/chargingStation_h5') : import('@/views/product/chargingStation'),
                hidden: true
          },
                // 配电运维及能耗分析
          {
                  path: '/distributionOperation',
                  component: () => !isPc ? import('@/views/product/distributionOperation_h5') : import('@/views/product/distributionOperation'),
                  hidden: true
            },
            // 发电站
            {
                  path: '/powerStation',
                  component: () => !isPc ? import('@/views/product/powerStation_h5') : import('@/views/product/powerStation'),
                  hidden: true
            },



        ]
      },

      {
        path: '/about',

        component: () => !isPc?import('@/views/about/Index_h5'):import('@/views/about/Index'),
        hidden: true,

      },
      {
        path: '/newsCenter',
        component: () => !isPc ? import('@/views/newsCenter/index_h5') : import('@/views/newsCenter/index'),
        hidden: true
      },
      {
        path: '/dynamics',
        component: () => !isPc ? import('@/views/dynamics/Index_h5') : import('@/views/dynamics/Index'),
        hidden: true
      },
      {
        path: '/trial',
        component: () => !isPc ? import('@/views/trial/Index_h5') : import('@/views/trial/Index'),
        hidden: true
      },
      {
        path: '/solution',
        redirect:"/v2g",
        hidden: true,
        children:[
          {
            path: '/v2g',
            component: () => !isPc ? import('@/views/solution/v2g/Index_h5') : import('@/views/solution/v2g/Index'),
            hidden: true
          },
          {
            path: '/business',
            component: () => !isPc ? import('@/views/solution/business/Index_h5') : import('@/views/solution/business/Index'),
            hidden: true
          },
          {
            path: '/smartPark',
            component: () => !isPc ? import('@/views/solution/smartPark/Index_h5') : import('@/views/solution/smartPark/Index'),
            hidden: true
          },
          {
            path: '/virtualPower',
            component: () => !isPc ? import('@/views/solution/virtualPower/Index_h5') : import('@/views/solution/virtualPower/Index'),
            hidden: true
          },
        ]
      },

    ]
  },
  // 基础路由

]
