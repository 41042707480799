import icon1 from "../../public/imgs/header/icon1.png";
import icon6 from "../../public/imgs/header/icon6.png";
import icon2 from "../../public/imgs/header/icon2.png";
import icon7 from "../../public/imgs/header/icon7.png";
import icon3 from "../../public/imgs/header/icon3.png";
import icon8 from "../../public/imgs/header/icon8.png";
import icon4 from "../../public/imgs/header/icon4.png";
import icon9 from "../../public/imgs/header/icon9.png";
import icon5 from "../../public/imgs/header/icon5.png";
import icon10 from "../../public/imgs/header/icon10.png";
import icon11 from "../../public/imgs/header/icon11.png";
import icon12 from "../../public/imgs/header/icon12.png";
import icon13 from "../../public/imgs/header/icon13.png";
import icon14 from "../../public/imgs/header/icon14.png";
import icon16 from "../../public/imgs/header/icon16.png";
import icon17 from "../../public/imgs/header/icon17.png";
import icon18 from "../../public/imgs/header/icon18.png";
import icon19 from "../../public/imgs/header/icon19.png";
import icon20 from "../../public/imgs/header/icon20.png";
import icon21 from "../../public/imgs/header/icon21.png";
import icon22 from "../../public/imgs/header/icon22.png";

/**
 * 配置信息
 * */


/** 菜单：产品列表 */
export const productList = [
  {
    label: "储能舱/储能柜",
    id:101,
    idParent: 10,
    path: "energyStorage",
    img: icon1,
    title: 'Option 1',
    msg: "全方位实时监测安全风险点",
  },
  {
    title: 'Option 1',
    id:102,
    label: "储能运营管理云控平台",
    idParent: 10,
    path: "cloud",
    img: icon6,
    msg: "主动发现并应对潜在的安全风险",
  },
  {
    label: "虚拟电厂资源聚合平台",
    idParent: 10,
    id:103,
    path: "building",
    img: icon2,
    msg: "用于整合和优化分布式能源的软件系统",
  },
  {
    label: "光伏电站运维平台",
    idParent: 10,
    id:104,
    path: "cloudpv",
    img: icon7,
    msg: "运营监测，保障光伏站安全、高效发电",
  },
  {
    label: "智慧零碳园区",
    idParent: 10,
    id:105,
    path: "carbonPark",
    img: icon3,
    msg: "对园区内全面监测、管理和优化",
  },
  {
    label: "充电站运营平台",
    idParent: 10,
    id:106,
    path: "chargingStation",
    img: icon8,
    msg: "用物联网实现充电桩、充电站的接入",
  },
  {
    label: "储能配置规划及评价平台",
    idParent: 10,
    id:107,
    path: "cloudServe",
    img: icon4,
    msg: "提供全面的能源储存系统分析和规划功能",
  },
  {
    label: "配电运维及能耗分析平台",
    idParent: 10,
    id:108,
    path: "distributionOperation",
    img: icon9,
    msg: "实现远程、集中、实时监控",
  },
  {
    label: "储能能量管理系统EMS",
    idParent: 10,
    id:109,
    path: "ems",
    img: icon5,
    msg: "实现对储能系统的智能监控、优化和实时控制",
  },
  {
    label: "发电站运维平台",
    idParent: 10,
    id:110,
    path: "powerStation",
    img: icon10,
    msg: "实现电厂监测部位状态与三维模型联动",
  },
]

/** 菜单配置 */
export const CustomerMenuList = [
  {
    label: "产品与服务",
    id: 1,
    path: "ems",
    parent: 10,
    key: '1',

    children: [
      {
        title: "产品",
        item: productList,
      },
      {
        title: "服务",
        item: [
          {
            label: "储能站建设、运营、运维",
            idParent: 10,
            id:111,
            path: "ems",
            img: icon11,
            msg: "全生命周期一条龙服务",
          },
          {
            label: "零碳园区建设、运营、运维",
            idParent: 10,
            id:112,
            path: "smartPark",
            img: icon12,
            msg: "降本增效，节能减排，让成本变收益",
          },
          {
            label: "光储充V2G建设、运营、运维",
            idParent: 10,
            id:113,
            path: "v2g",
            img: icon13,
            msg: "核心算法，实现光、储、充、网联动",
          },
          {
            label: "虚拟电厂聚合服务",
            idParent: 10,
            id:114,
            path: "building",
            img: icon14,
            msg: "页面操作一件执行，复杂问题轻松解决",
          },
        ],
      },
    ],
  },
  {
    label: "解决方案",
    id: 3,
    key: '2',
    path: "technology",
    parent: 20,
    children: [
      {
        title: "解决方案",
        item: [
          {
            label: "工商业储能系统解决方案",
            idParent: 20,
            id:201,
            path: "business",
            img: icon19,
            msg: "全方位实时监测安全风险点",
          },
          {
            label: "光储充V2G电站解决方案",
            idParent: 20,
            img: icon20,
            id:202,
            path: "v2g",
            msg: "有效减少充电站高峰期的电网负荷",
          },
          {
            label: "虚拟电厂解决方案",
            idParent: 20,
            id:203,
            img: icon21,
            path: "virtualPower",
            msg: "应用于多种分布式能源资源的聚合",
          },
          {
            label: "智慧零碳园区解决方案",
            idParent: 20,
            id:204,
            img: icon22,
            path: "smartPark",
            msg: "助力园区管理向信息化、智慧化转变",
          },
        ],
      },
    ],
  },
  {
    title: 'Option 1',
    label: "客户案例",
    id: 4,
    key: '3',
    path: "dynamics",
    parent: 0,

  },
  {
    label: "新闻中心",
    id: 7,
    key: '4',
    path: "newsCenter",
    parent: 2,
    title: 'Option 1',
  },
  {
    label: "关于我们",
    id: 6,
    key: '5',
    path: "about",
    parent: 2,
    title: 'Option 1',
  },
]
//手机端配置
export const CustomerMenuListPhone = [
  {
    label: "产品与服务",
    id: 1,
    path: "ems",
    parent: 10,
    title: 'Option 1',
    key: '1',
    children: [
      {
        label: "产品",
        id:1001,
        children: productList,
      },
      {
        label: "服务",
        id:1002,
        children: [
          {
            label: "储能站建设、运营、运维",
            idParent: 10,
            id:111,
            path: "ems",
            img: icon11,
            msg: "全生命周期一条龙服务",
          },
          {
            label: "零碳园区建设、运营、运维",
            idParent: 10,
            id:112,
            path: "cloud",
            img: icon12,
            msg: "降本增效，节能减排，让成本变收益",
          },
          {
            label: "光储充V2G建设、运营、运维",
            idParent: 10,
            id:113,
            path: "cloudServe",
            img: icon13,
            msg: "核心算法，实现光、储、充、网联动",
          },
          {
            label: "虚拟电厂聚合服务",
            idParent: 10,
            id:114,
            path: "cloudpv",
            img: icon14,
            msg: "页面操作一件执行，复杂问题轻松解决",
          },
        ],
      },
    ],
  },
  {
    label: "解决方案",
    id: 3,
    key: '2',
    path: "technology",
    parent: 20,
    children: [
          {
            label: "工商业储能系统解决方案",
            idParent: 20,
            path: "business",
            img: icon1,
            id:201,
            msg: "全方位实时监测安全风险点",
          },
          {
            label: "光储充V2G电站解决方案",
            idParent: 20,
            img: icon16,
            id:202,
            path: "v2g",
            msg: "有效减少充电站高峰期的电网负荷",
          },
          {
            label: "虚拟电厂解决方案",
            idParent: 20,
            img: icon17,
            id:203,
            path: "virtualPower",
            msg: "应用于多种分布式能源资源的聚合",
          },
          {
            label: "智慧零碳园区解决方案",
            idParent: 20,
            id:204,
            img: icon18,
            path: "smartPark",
            msg: "助力园区管理向信息化、智慧化转变",
          },

    ],
  },
  {
    title: 'Option 1',
    label: "客户案例",
    id: 4,
    key: '3',
    path: "dynamics",
    parent: 0,

  },
  {
    label: "新闻中心",
    id: 7,
    key: '4',
    path: "newsCenter",
    parent: 2,
    title: 'Option 1',
  },
  {
    label: "关于我们",
    id: 6,
    key: '5',
    path: "about",
    parent: 2,
    title: 'Option 1',
  },
]
