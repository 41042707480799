<template>
  <div class="layout">
    <div class="layout-header" v-if="iSheader">
      <layout-header></layout-header>
    </div>
    <div class="layout-content">
      <router-view />
    </div>
    <!-- 返回顶部按钮 -->
    <!-- <transition name="breathing">
      <div class="backTopBtn">
        <div style="display: flex" class="ReturnTop_returnTop__bzmtH">
          <span class="easyv-icon ReturnTop_icon__JOMdy"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path
                d="M12.8 5.873V22.8a.2.2 0 01-.2.2h-1.877a.2.2 0 01-.2-.2V5.873l-3.38 3.808a.2.2 0 01-.299 0L5.518 8.186a.2.2 0 010-.265l5.994-6.752a.2.2 0 01.299 0l5.993 6.752a.2.2 0 010 .265l-1.326 1.495a.2.2 0 01-.3 0L12.8 5.873z"
              ></path></svg
          ></span>
        </div>
        <div class="rect_three" @click="backTopBtn"></div>
      </div>
    </transition> -->
    <div class="layout-footer" v-if="isfooter">
      <layout-footer></layout-footer>
    </div>
     <a-back-top />
  </div>
</template>
<script>
import LayoutFooter from "./LayoutFooter.vue";
import LayoutHeader from "./LayoutHeader.vue";
export default {
  components: {
    LayoutHeader,
    LayoutFooter,
  },
  data() {
    return {
      isfooter: true,
      iSheader: true,
    };
  },
  watch: {
    // 监听，当路由发生变化的时候执行
    $route: {
      // $route可以用引号，也可以不用引号
      handler(to) {
        if (to.path == "/trial") {
          this.isfooter = false;
        } else {
          this.isfooter = true;
        }
        /** route切换 页面滚回顶部 */
        window.scrollTo({
          top: 0,
          behavior: 'instant' //instant：瞬间 smooth:平滑
        });
      },
      deep: true, // 深度观察监听
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.ant-back-top-content{
    background-color: #0874ff!important;
}
.backTopBtn {
  width: 7.928571rem;
  height: 18.571429rem;
  background-color: #5ba3d8;
  position: fixed;
  z-index: 999;
  bottom: 2.428571rem;
  right: 2.428571rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .backTopBtn_img {
    width: 7.928571rem;
    height: 18.571429rem;
    pointer-events: none;
  }
  .rect_one {
    position: absolute;
    top: 5.957143rem;
    left: 2.142857rem;
    width: 4.542857rem;
    height: 3.142857rem;
    opacity: 0;
  }
  .rect_two {
    position: absolute;
    top: 9.957143rem;
    left: 2.142857rem;
    width: 4.542857rem;
    height: 3.142857rem;
    opacity: 0;
  }
  .rect_three {
    position: absolute;
    top: 13.957143rem;
    left: 2.142857rem;
    width: 4.542857rem;
    height: 3.142857rem;
    opacity: 0;
  }
  .qrCodeTransition-enter-active,
  .qrCodeTransition-leave-active {
    transition: opacity 0.5s;
  }
  .qrCodeTransition-enter,
  .qrCodeTransition-leave-to {
    opacity: 0;
  }
  .qrCodeRect1 {
    width: 200px;
    height: 200px;
    background-color: pink;
    position: absolute;
    left: -17.142857rem;
    top: 0;
  }

  .qrCodeRect2 {
    width: 200px;
    height: 200px;
    background-color: red;
    position: absolute;
    left: -17.142857rem;
    top: 0;
  }
}

.layout {
  display: flex;
  width: 100%;
  min-width: 1000px;
  height: 100%;
  flex-direction: column;
}
.layout-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 60;
}
.layout-content {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.layout-content .layout-menu {
  overflow: auto;
  width: 17.57rem;
}
.layout-content .layout-view {
  flex: 1;
  padding: 0 1.42rem 1.42rem 0;
  overflow: auto;
}
</style>
