<template>
  <div class="header" :class="headerClass" v-show="iSheader">
    <div class="header_left PangMenZhengDao" @click="gohome">
      <img src="../../public/imgs/logo.png" />
      <div class="logo_text">
        <div class="logo_text_title">湖北省复变时空能源科技有限公司</div>
        <div style="font-size: 1.0714rem" class="logo_text_en">
          WuHan Complex Dimension Data Technology Co.Ltd.
        </div>
      </div>
    </div>
    <div class="layout-menu"><customer-menu></customer-menu></div>
    <div class="header_right">
      <div class="link" @click="$router.push({ path: '/trial' })">申请试用</div>
    </div>
  </div>
</template>
<script>
import CustomerMenu from "./CustomerMenu.vue";
export default {
  components: {
    CustomerMenu,
  },
  data() {
    return {
      headerClass: "",
      iSheader: true,
    };
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  watch: {
    // 监听，当路由发生变化的时候执行
    $route: {
      // $route可以用引号，也可以不用引号
      handler(to) {
        if (to.path == "/trial") {
          this.headerClass = "write";
        }
        else if (to.path == "/dynamics"||to.path == "/index" || to.path == "/newsCenter") {
          this.headerClass = "bleack";
        }else{
          this.headerClass = "";
        }
      },
      deep: true, // 深度观察监听
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  methods: {
    gohome() {
      this.$router.push("./");
    },
    handleScroll() {
      console.log(this.$route.path);
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.$route.path !== "/trial") {
        if (scrollTop > 10) {
          this.headerClass = "write";
        } else {
          if (this.$route.path == "/dynamics" || this.$route.path == "/index") {
            this.headerClass = "bleack";
          } else {
            this.headerClass = "";
          }
        }
      } else {
        this.headerClass = "write";
      }

      if (this.$route.path != "./") {
        this.iSheader = true;
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        console.log(scrollTop, 5222222);
        if (scrollTop > 800) {
          this.iSheader = false;
        } else {
          this.iSheader = true;
        }
      }
    },
  },
};
</script>
<style  lang="less" scoped>
@media screen and (max-width: 768px) {
  .layout-menu {
    display: none;
  }
}
.logo_text {
  //height: 4.1429rem;
  text-align: left;
}
.logo_text_title {
  font-family: PangMenZhengDao;
  font-size: 1.714rem  /* 24/14 */;
  line-height: 1.929rem  /* 27/14 */;
  //font-size: 2.2857rem;
  //line-height: 2.6429rem;
  text-align: left;
  font-style: normal;
}
.logo_text_en {


  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  //font-size: 1.1429rem;
  //line-height: 1.5714rem;
  //letter-spacing: 1px;
  font-size: 0.857rem  /* 12/14 */;
  line-height: 17px;
  //letter-spacing: 1px;
  letter-spacing: -0.6px;
}
.header.write {
  background-color: #fff;
  color: #444;
  .header_right {
    > div {
      border-color: #666;
    }
  }
}
.header:hover {
  background-color: #fff;
  color: #181a24;
  transition: all 0.2s;
  .header_left {
    display: flex;
    align-items: center;
    // font-weight: 600;
    color: #181a24;
    cursor: pointer;
  }
  .header_right {
    > div {
      border-color: #666;
    }
  }
}
.bleack {
  color: #1c1e28 !important;
}
.header {
  color: #fff;
  width: 100%;
  padding: 0 10rem 0px 5.7143rem; /*48*/
  display: flex;
  position: relative;
  z-index: 99;
  // height: 8.5714rem;
  transition: all 0.2s;

  .header_left {
    font-size: 2.5714285714286rem; /*36*/
    // line-height: 5.714285714286rem;/*80*/
    display: flex;
    align-items: center;
    //margin-top: 2.2rem;

    img {
      width: 2.5714285714286rem; /*36*/
      height: 2.5714285714286rem; /*36*/
      margin-right: 0.857rem  /* 12/14 */;
      //margin-top: 0.7143rem;
    }
  }
  .layout-menu {
    flex: 1;
  }
  .header_right {
    margin-left: 2.571rem  /* 36/14 */;
    display: flex;
    align-items: center;
    > div {
      //width: 8.8571rem;
      //height: 3.4286rem;
      //background: linear-gradient(313deg, #0874ff 0%, #00d7ff 100%);
      //border-radius: 0.2857rem;
      width: 6.571rem  /* 92/14 */;
      height: 2.286rem  /* 32/14 */;
      background: linear-gradient( 313deg, #0874FF 0%, #00D7FF 100%);
      border-radius: 0.286rem  /* 4/14 */;
      //line-height: 3.4286rem;
      line-height: 2.286rem  /* 32/14 */;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      //font-size: 1.4286rem;
      font-size: 1rem  /* 14/14 */;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
</style>
