<template>
  <div class="footer">
    <div class="phone">
      <div class="title_bottom">一键开启储能技术平台服务</div>
      <div class="flex margin">
        <a-input class="connection" placeholder="请留下您的联系方式"></a-input> <a-button class="btn">联系我们</a-button>
      </div>
    </div>
    <div class="footer_bottom">
      <div class="flex">
        <div class="icon">
          <img src="../../public/imgs/icon.png" />
        </div>
        <div class="item">
          <div class="title_bottom2">关于我们</div>
          <div class="content" @click="$router.push({path:'/about'})">企业简介</div>
          <div class="content">联系我们</div>
          <div class="content">人才招聘</div>

          <div class="item" style="margin-top: 4.5714rem">
            <div class="title_bottom2">友情链接</div>
            <div class="content" @click="openWindow('https://www.cddiot.com/')">复变时空互联科技</div>
          </div>
        </div>
        <div class="item" style="margin-right: 6.1429rem">
          <div class="title_bottom2">产品与服务</div>
          <div class="content" @click="$router.push({path:'/business'})">工商业储能</div>
          <div class="content" @click="$router.push({path:'/smartPark'})">零碳园区</div>
          <div class="content" @click="$router.push({path:'/v2g'})">光储充V2G</div>
        </div>
        <div class="item" style="margin-right: 7.0714rem">
          <div class="title_bottom2">解决方案</div>
          <div class="content" @click="$router.push({path:'/business'})">工商业储能</div>
          <div class="content" @click="$router.push({path:'/smartPark'})">零碳园区</div>
          <div class="content" @click="$router.push({path:'/v2g'})">光储充V2G</div>
        </div>
        <div class="item" style="margin-right: 6.9286rem">
          <div class="title_bottom2">服务与支持</div>
          <div class="content">网站备案</div>
          <div class="content">服务咨询</div>
          <div class="content">建议与反馈</div>
          <div class="content">投诉举报</div>
        </div>
        <div class="item">
          <div class="title_bottom2">联系我们</div>
          <div class="content">业务咨询：xiehaonan@cddiot.com</div>
<!--          <div class="content">商务合作：marketing@cddiot.com</div>-->
          <div class="content">电话：133 0712 0832</div>
          <div class="content">地址：湖北省武汉市洪山区产业大楼8楼</div>
          <div class="flex" style="opacity: 0;">
            <div class="code">
              <div class="imgcode"></div>
              <div class="code_text">微信公众号</div>
            </div>
            <div class="code">
              <div class="imgcode"></div>
              <div class="code_text">视频号</div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer_line">
        <div class="footer_line_content">
          @2019-{{ new Date().getFullYear()}} 湖北省复变时空能源科技有限公司 版权所有 <a target="_blank" href="https://beian.miit.gov.cn/">鄂ICP备20001581号</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    openWindow(url, target="_blank"){
      window.open(url, target)
    }
  },
};
</script>
<style  lang="less" scoped>
:deep(.ant-input.connection){
  border-radius: 0.571rem  /* 8/14 */ 0 0 0.571rem  /* 8/14 */;
}
.phone {
  background: url("../../public/imgs/3.png") no-repeat 100% 100%;
  height: 33.1429rem;
    background-size: cover;
  .ant-input.connection::placeholder{
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 1.286rem  /* 18/14 */;
    color: #3F4758;
    text-align: left;
    font-style: normal;
  }
  .title_bottom {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 2.571rem  /* 36/14 */;
    color: #181a24;
    line-height: 4.2143rem;
    padding: 7.1429rem 0px 5.1429rem 0px;
  }
  .margin {
    width: 38.3571rem;
    height: 4.4286rem;
    margin: auto;
  }
  .btn {
    width: 11.4286rem;
    height: 4.4286rem;
    background: linear-gradient(313deg, #0874ff 0%, #00d7ff 100%);
    border-radius: 0.2857rem;
    margin-left: -0.2857rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 1.7143rem;
    color: #ffffff;
  }
}
.footer_bottom {
  width: 100%;
  background: #151515;
  padding-top: 4.5714rem;
  padding-left: 18.5714rem;
  .icon {
    width: 12.5714rem;
    height: 3rem;
    margin-right: 9.1429rem;
  }
  .item {
    margin-right: 4.1429rem;
    .title_bottom2 {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 1.4286rem;
      color: #ffffff;
      line-height: 2rem;
      text-align: left;
      margin-bottom: 2.2857rem;
    }
    .content {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 1.1429rem;
      color: rgba(255, 255, 255, 0.7);
      line-height: 1.5714rem;
      text-align: left;
      margin-bottom: 1.4286rem;
      cursor: pointer;
    }
  }
  .code {
    margin-right: 2.2857rem;
    margin-top: 2.1429rem;
    .imgcode {
      width: 7.1429rem;
      height: 7.1429rem;
      border: 1px solid #fff;
    }
    .code_text {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 1.1429rem;
      color: #ffffff;
      line-height: 1.5714rem;
      padding-top: 10px;
    }
  }
  .footer_line {
    margin-top: 3.7143rem;
    margin: 3.7143rem 18.5714rem 0px 0px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    .footer_line_content {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 1.1429rem;
      color: #ffffff;
      line-height: 1.5714rem;
      padding-bottom: 2.5rem;
      padding-top: 1.6429rem;
      a{
        color: #fff;
      }
    }
  }
}
</style>
