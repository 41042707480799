<template>
  <div class="menu">
<!--    :class="current === item.path ? 'current' : ''"-->
<!--  update by liuranli 2024-05-31: 导航栏改为鼠标悬浮变色，点击后不会有被选中的样式  -->
<!--    :class="$route.path?.split('/')?.[1] === item.path ? 'current' : ''"-->
    <div
      v-for="item in list"
      :key="item.path"
      class="item"
    >
      <div class="labelTitle" @click="clickTab(item)">{{ item.label }}</div>
      <div class="childrenmask" v-if="item.children">
        <div class="childrendiv" v-if="item.children">
          <div class="children flex">
            <div
              style="margin-right: 6.9286rem"
              class="box1"
              v-for="em in item.children"
              :key="em"
            >
              <div class="title">{{ em.title }}</div>
              <div class="flex" style="flex-wrap: wrap; justify-content: space-between; margin-top: 1.0714rem">
                <div
                  class="childrenitem"
                  v-for="c in em.item"
                  :key="c.path"
                  @click="clickTab(c)"
                >
                  <div class="flex">
                    <div
                      style="
                        width: 5.714rem  /* 80/14 */;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        /*padding-top: 0.857rem  !* 12/14 *!;*/
                      "
                    >
                      <img
                        :src="c.img"
                        style="align-self: flex-start;height: 2.571rem  /* 36/14 */; width: 2.571rem  /* 36/14 */;"
                      />
                    </div>
                    <div class="flex-1">
                      <div class="label1">{{ c.label }}</div>
                      <div class="msg1">{{ c.msg }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card_r" v-if="item.children.length == 1" @click="gotoFn">
              <div class="title1">复变时空能源科技</div>
              <div class="text1">助力企业快速实施方案</div>
              <div class="details align-items flex">
                查看详情
                <img
                  src="../../public/imgs/home/icon19.png"
                  style="
                    width: 1.0714rem;
                    height: 0.7143rem;
                    margin-left: 0.5714rem;
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {CustomerMenuList} from "@/utils/config";
export default {
  components: {},
  data() {
    return {
      list:CustomerMenuList,
      current: sessionStorage.getItem("menuTab") || "index",
    };
  },
  watch: {
    // 监听，当路由发生变化的时候执行
    $route: {
      // $route可以用引号，也可以不用引号
      handler(to) {
        sessionStorage.setItem("menuTab", to.path);
      },
      deep: true, // 深度观察监听
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },

  methods: {
    gotoFn(){
    this.$router.push('./dynamics')
    },
    clickTab(item) {
      console.log(item);
      if (!item.children) {
        this.$router.push(item.path);
        this.list.forEach((element) => {
          if (element.parent == item.idParent) {
            element.path = item.path;
          }
        });
      }
      console.log(item);
      sessionStorage.setItem("menuTab", item.path);
      this.current = item.path;
    },
  },
};
</script>
<style scoped lang="less">
.menu {
  width: 100%;
  height: 100%;
  display: flex;

  justify-content: end;
  > div {
    line-height: 5.714285714286rem; /*80*/
    margin-right: 5.714285714286rem; /*80*/
  }
  .item {
    cursor: pointer;
    font-size: 1.286rem  /* 18/14 */;
    //font-size: 1.4286rem; /*18px*/
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    line-height: 6rem  /* 84/14 */;
    //line-height: 8.5714rem; /*120*/
    //min-width: 11.429rem  /* 160/14 */;
    min-width: 10.143rem  /* 142/14 */;
    margin-right: 0 !important;
    .childrendiv {
      display: none;
    }
    .childrenmask {
      display: none;
    }
  }
  //定义动画
  @keyframes fadeIn {
    0% {
      transform: translateY(-5rem  /* -70/14 */) scale(0.9);
    }
    100% {
      transform: translateY(0) scale(1);
    }
  }
  .item:hover {
     color: rgba(24, 125, 253, 1);
    .labelTitle{
      position: relative;
      &:before{
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 1.286rem  /* 18/14 */;
        width: 5.143rem  /* 72/14 */;
        height: 0.143rem  /* 2/14 */;
        background: #0874FF;
      }
    }
    .childrendiv {
      display: block;
      //animation: fadeIn 0.3s;
      // height: 560px;
      .childrenitem {
        display: flex;
        align-items: center;
        width: 29.286rem  /* 410/14 */;
        //width: 29.2857rem;
        // height: 6.7143rem;
        font-size: 1.142857142857143rem; /*16px*/

        height: 6.5714rem; /*40*/
        // padding: 0 1.43rem;
        white-space: nowrap;
        //width: 50%;
        //margin-bottom: 1.5rem;
        margin-bottom: 0.571rem  /* 8/14 */;
        //padding-left: 0.5rem;
        box-sizing: border-box;
        .flex-1 {
          //padding-top: 0.857rem  /* 12/14 */;
          .label1 {
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            //font-size: 1.4286rem;
            color: #181a24;
            line-height: 2rem;
            text-align: left;
            font-style: normal;
            font-size: 1.143rem  /* 16/14 */;

          }
          .msg1 {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            //font-size: 1rem  /* 14/14 */;
            //font-size: 1.1429rem;
            color: #3f4758;
            line-height: 2.1429rem;
            text-align: left;
            font-style: normal;
            font-size: 1rem  /* 14/14 */;
          }
        }
      }
      .childrenitem:hover {
        background: url("../../public/imgs/header.png") no-repeat;
        background-size: 100% 100%;
         height: 6.5714rem;

        .label1 {
          color: #187dfd;
        }
      }
    }
    .childrenmask {
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      // height: 100%; /*40*/
      //border-top: 1px solid #dfdede;
      color: #181a24;
      background-color: #fff;
      box-shadow: 0rem  /* 0/14 */ 1.429rem  /* 20/14 */ 1.429rem  /* 20/14 */ 0rem  /* 0/14 */ rgba(0,0,0,0.2);
    }
  }
  .children {
    // position: absolute;
    // // display: flex;
    margin-left: 21.3571rem;
    text-align: left;
    .title {
      border-bottom: 1px solid #ced5dd;
      height: 3.0714rem;
      padding-bottom: 1.0714rem;
      line-height: 3.0714rem;
      //margin-top: 2.857rem  /* 40/14 */;
      margin-top: 1.429rem  /* 20/14 */;
      //margin-top: 4.1429rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 1.143rem  /* 16/14 */;
      color: #3F4758;
      text-align: left;
      font-style: normal;
    }
    .box1 {
      width: 61.5rem;
    }
    .box1:nth-of-type(1) {
      width: 62.1429rem;
    }
    .box1:nth-of-type(2) {
      width: 29.286rem  /* 410/14 */;
      //width: 24.3571rem;
      .childrenitem {
        width: 100%;
      }
    }
  }
  .current {
    margin-right: 5.714285714286rem; /*80*/
    //line-height: 8.5714rem; /*80*/
    color: rgba(24, 125, 253, 1);

    a {
      color: rgba(24, 125, 253, 1);
    }
  }
  .card_r {
    width: 22.857rem  /* 320/14 */;
    height: 14.286rem  /* 200/14 */;
    background: url("../../public/imgs/header/icon15.png") no-repeat;
    background-size: 100% 100%;
    //padding: 2.2857rem 1.7143rem;
    // width: 22.8571rem;
    //height:16.7143rem;
    margin-top: 4.714rem  /* 66/14 */;
    padding: 2.571rem  /* 36/14 */ 0 0 1.714rem  /* 24/14 */;
    margin-bottom: 3.714rem  /* 52/14 */;
    .title1 {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 1.4286rem;
      color: #181a24;
      line-height: 2rem;
      text-align: left;
      margin-bottom: 0.5714rem;
    }

    .text1 {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 0.8571rem;
      color: #3f4758;
      line-height: 1.2143rem;
      text-align: left;
    }
  }
  .details {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 1.1429rem;
    color: #0874ff;
    line-height: 1.5714rem;
    text-align: left;
    cursor: pointer;
    margin-top: 2.5714rem;
  }
}
</style>
