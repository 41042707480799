<template>
  <div class="header" :class="headerClass" v-show="iSheader">
    <div class="header_left PangMenZhengDao" @click="gohome">
      <img src="../../public/imgs/logo.png" />
      <div class="logo_text">
        <div class="logo_text_title">湖北省复变时空能源科技有限公司</div>
        <div class="logo_text_en">
          WuHan Complex Dimension Data Technology Co.Ltd.
        </div>
      </div>
    </div>
    <div class="header_right">
      <div class="link" @click="$router.push({ path: '/trial' })">申请试用</div>
    </div>
    <div class="icon">
      <MenuOutlined v-show="!isMobileNavListShow" @click="gotoArticle" />
      <CloseOutlined
        v-show="isMobileNavListShow"
        @click="isMobileNavListShow = false"
      />
    </div>
    <div class="mobileNavList_wrapper__ZqZVE" v-show="isMobileNavListShow">
      <!-- <a-menu
        v-model:openKeys="state.openKeys"
        v-model:selectedKeys="state.selectedKeys"
        mode="inline"
        theme="dark"
        :inline-collapsed="state.collapsed"
        :items="getItem"
      ></a-menu> -->
      <CustomerMenu2
        @menuCollapse="isMobileNavListShow = false"
      ></CustomerMenu2>
    </div>
  </div>
</template>
<script>
import { MenuOutlined, CloseOutlined } from "@ant-design/icons-vue";
import { CustomerMenuList } from "@/utils/config";
import CustomerMenu2 from "./CustomerMenu2.vue";
export default {
  components: {
    MenuOutlined,
    CloseOutlined,
    CustomerMenu2,
  },

  data() {
    return {
      state: {
        collapsed: false,
        selectedKeys: [],
        openKeys: [],
        preOpenKeys: [],
      },
      getItem: CustomerMenuList,
      current: sessionStorage.getItem("menuTab") || "index",
      isMobileNavListShow: false,
      headerClass: "",
      iSheader: true,
    };
  },
  created() {
    console.log(this.getItem, 96666);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  watch: {
    // 监听，当路由发生变化的时候执行
    $route: {
      // $route可以用引号，也可以不用引号
      handler(to) {
        sessionStorage.setItem("menuTab", to.path);
        if (to.path == "/trial") {
          this.headerClass = "write";
        } else if (to.path == "/dynamics" || to.path == "/index") {
          this.headerClass = "bleack";
        } else {
          this.headerClass = "";
        }
      },
      deep: true, // 深度观察监听
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  methods: {
    gotoArticle() {
      this.isMobileNavListShow = true;
    },
    gohome() {
      this.$router.push("./");
    },
    handleScroll() {
      console.log(this.$route.path);
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.$route.path !== "/trial") {
        if (scrollTop > 10) {
          this.headerClass = "write";
        } else {
          if (this.$route.path == "/dynamics") {
            this.headerClass = "bleack";
          } else {
            this.headerClass = "";
          }
        }
      } else {
        this.headerClass = "write";
      }

      if (this.$route.path != "./") {
        this.iSheader = true;
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        console.log(scrollTop, 5222222);
        if (scrollTop > 800) {
          this.iSheader = false;
        } else {
          this.iSheader = true;
        }
      }
    },
  },
};
</script>
<style  lang="less" scoped>
.mobileNavList_wrapper__ZqZVE {
  position: fixed;
  top: 0.87rem;
  right: 0;
  bottom: 0;
  left: 0;
  height: calc(100vh - 0.87rem);
  padding: 0 0.5rem;
  overflow: auto;
  background: #fff;
}
.logo_text {
  //height: 4.1429rem;
  text-align: left;
}
.logo_text_title {
  font-family: PangMenZhengDao;
  font-size: 0.24rem; /* 24/14 */

  //font-size: 2.2857rem;
  //line-height: 2.6429rem;
  text-align: left;
  font-style: normal;
}
.logo_text_en {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 0.14rem;
  letter-spacing: -0.6px;
}
.header.write {
  background-color: #fff;
  color: #444;
  .header_right {
    > div {
      border-color: #666;
    }
  }
}
.header:hover {
  background-color: #fff;
  color: #181a24;
  transition: all 0.2s;
  .header_left {
    display: flex;
    align-items: center;
    // font-weight: 600;
    color: #181a24;
    cursor: pointer;
  }
  .header_right {
    > div {
      border-color: #666;
    }
  }
}
.bleack {
  color: #1c1e28 !important;
}
.header {
  color: #fff;
  width: 100%;
  display: flex;
  position: relative;
  z-index: 99;
  transition: all 0.2s;
  padding-top: 0.18rem;
  height: 0.87rem;
  .header_left {
    font-size: 2.5714285714286rem; /*36*/
    // line-height: 5.714285714286rem;/*80*/
    display: flex;
    align-items: center;
    //margin-top: 2.2rem;

    img {
      width: 0.4rem;
      height: 0.44rem;
      margin-left: 0.24rem /* 12/14 */;
      margin-right: 0.06rem;
      //margin-top: 0.7143rem;
    }
  }
  .layout-menu {
    flex: 1;
  }
  .header_right {
    margin-left: 1.2rem /* 36/14 */;
    display: flex;
    align-items: center;
    > div {
      width: 1.32rem;
      height: 0.56rem;
      background: linear-gradient(313deg, #0874ff 0%, #00d7ff 100%);
      border-radius: 0.08rem /* 4/14 */;
      line-height: 0.56rem; /* 32/14 */
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 0.24rem /* 14/14 */;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .icon {
    display: flex;
    align-items: center;
    width: 0.28rem  /* 14/50 */;
    height: 0.28rem  /* 14/50 */;
    margin-left: 0.16rem;
    margin-top: 0.1rem;
    :deep(svg){
      width: 100%;
      height: 100%;
    }
  }
}
</style>
