<template>
  <div class="nav-bar" :class="{ collapsed: collapsed }">
    <a-menu
      mode="inline"
      :open-keys="openKeys"
      @openChange="onOpenChange"
      :selectedKeys="defaultKey"
      :default-selected-keys="defaultKey"
      :inline-collapsed="collapsed"
    >
      <template v-for="item in routerList">
        <a-menu-item
          v-if="!item.children"
          :key="item.id"
          @click="onMenuClick(item)"
        >
          <span>{{ item.label }}</span>
        </a-menu-item>
        <a-sub-menu v-else :key="item.id">
          <template #title>{{ item.label }}</template>
          <template v-for="child in item.children">
            <a-menu-item
              :key="child.key"
              v-if="!child.children"
              @click="onMenuClick(child)"
            >
              <span>{{ child.label }}</span>
            </a-menu-item>
            <a-sub-menu v-else :key="child.id">
              <template #title>{{ child.label }}</template>
              <a-menu-item
                :key="grand.id"
                v-for="grand in child.children"
                @click="onMenuClick(grand)"
              >
                <span>{{ grand.label }}</span>
              </a-menu-item>
            </a-sub-menu>
          </template>
        </a-sub-menu>
      </template>
    </a-menu>
  </div>
</template>
<script>
import { CustomerMenuListPhone } from "@/utils/config";
export default {
  data() {
    return {
      routerList: CustomerMenuListPhone,
      rootSubmenuKeys: [],
      openKeys: [],
      collapsed: false,
      defaultKey: [],
    };
  },
  created() {
    this.getDataList();
  },
  // computed: {
  //   path() {
  //     return this.$route.path
  //   }
  // },
  methods: {
    // 获取菜单列表数据
    getDataList() {
      this.setDefaultAndOpenKeys();
    },
    // 设置默认defaultKey和openKeys
    setDefaultAndOpenKeys() {
      // const allKey = [];
      // this.routerList.map((item) => {
      //   allKey.push(item.key);
      // });
      // this.rootSubmenuKeys = allKey;
      // const key = localStorage.getItem("currentMenuKey");
      // const dk = key ? [key] : [this.routerList[0].id];
      // this.defaultKey = dk;
      // const open = localStorage.getItem("currentOpenKeys");
      // console.log(open, this.routerList[0].id, "默认打开");
      // if (open || this.routerList[0].children) {
      //   const ok = open ? [open] : [this.routerList[0].id];
      //   this.openKeys = ok;
      //   console.log(this.openKeys, "默认打开");
      // }
    },
    onOpenChange(openKeys) {
      console.log(openKeys, "openKeys");
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
      localStorage.setItem("currentOpenKeys", openKeys[openKeys.length - 1]);
    },
    onMenuClick(item) {
      console.log(item, 988888);
      this.$router.push(item.path);
      this.defaultKey = [item.id];
      localStorage.setItem("currentMenuKey", item.id);
      this.$emit("menuCollapse");
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
      localStorage.setItem("collapsed", this.collapsed);
    },
  },
};
</script>
<style lang="less">
/deep/.ant-menu-inline {
  border-right: 0px;
}
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
      border-right: 0px solid #f0f0f0;
}
// /deep/ .nav-bar .ant-menu-item-selected{
// color: #000;
// }
.nav-bar {
  width: 100%;
  // overflow-y: auto;
  position: relative;

  .ant-menu {
    height: 100%;
    // background: #09193a;
    padding: 8px 0 0 0;
  }

  //   .ant-menu-item-selected,
  //   .ant-menu-submenu-selected {
  //     color: #ffffff !important;
  //     .title-img {
  //       -webkit-filter: grayscale(0);
  //       filter: grayscale(0);
  //     }
  //   }
}
// .ant-menu-item:hover {
//   color: #ffffff !important;
// }

.nav-bar.collapsed {
  width: 52px;
  transition: all 0.6s ease;

  .collapse-btn {
    right: 15px;
  }

  .ant-menu-inline-collapsed {
    width: 52px;
  }

  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0 0 0 18px !important;
  }

  .ant-menu-item {
    color: #d3d3d3 !important;
    padding: 0 16px !important;

    & > span {
      display: none;
    }
  }
}
</style>