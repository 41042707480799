<template>
  <div class="footer">
    <div class="phone">
      <div class="title_bottom">一键开启储能技术平台服务</div>
      <div class="flex margin">
        <a-input class="connection" placeholder="请留下您的联系方式"></a-input>
        <a-button class="btn">联系我们</a-button>
      </div>
    </div>
    <div class="footer_bottom">
      <div class="item">
        <div class="title_bottom2">联系我们</div>
        <div class="content">业务咨询：xiehaonan@cddiot.com</div>
<!--        <div class="content">商务合作：marketing@cddiot.com</div>-->
        <div class="content">电话：133 0712 0832</div>
        <div class="content">地址：湖北省武汉市洪山区产业大楼8楼</div>
        <div class="wx flex" style="justify-content: space-around;display: none">
          <div class="code">
            <div class="imgcode"></div>
            <div class="code_text">微信公众号</div>
          </div>
          <div class="code">
            <div class="imgcode"></div>
            <div class="code_text">视频号</div>
          </div>
        </div>
      </div>
      <div class="footer_line">
        <div class="footer_line_content">
          @2019-{{ new Date().getFullYear()}} 湖北省复变时空能源科技有限公司 版权所有 <br/> <a style="color: #ffffff" target="_blank" href="https://beian.miit.gov.cn/">鄂ICP备20001581号</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    openWindow(url, target = "_blank") {
      window.open(url, target);
    },
  },
};
</script>
<style  lang="less" scoped>
.phone {
  background: url("../../public/imgs/home_h5/footer.png") no-repeat 100% 100%;
  height: 6.52rem;
  background-size: cover;
  .ant-input.connection::placeholder {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 0.24rem /* 18/14 */;
    color: #3f4758;
    text-align: left;
    font-style: normal;
  }
  .title_bottom {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 0.48rem; /* 36/14 */

    color: #181a24;
    line-height: 0.66rem;
    padding: 2.08rem 0px 0.64rem 0px;
  }
  .margin {
    // width: 38.3571rem;
    height: 0.84rem;
    margin: auto 1.04rem;
  }
  .btn {
    // width: 11.4286rem;
    height: 0.84rem;
    background: linear-gradient(313deg, #0874ff 0%, #00d7ff 100%);
    border-radius: 0.08rem;
    margin-left: -0.2857rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 0.32rem;
    color: #ffffff;
  }
}
.footer_bottom {
  width: 100%;
  background: #151515;
  padding-top: 0.64rem;
  div.item{
    div.wx{
      max-width: 6rem  /* 300/50 */;
      margin: 0 auto;
    }
  }
  .icon {
    width: 12.5714rem;
    height: 3rem;
    margin-right: 9.1429rem;
  }
  .item {
    padding-left: 32px;
    // margin-right: 4.1429rem;
    .title_bottom2 {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 0.32rem;
      color: #ffffff;
      // line-height: 0.64rem;
      text-align: left;
      margin-bottom: 0.4rem;
    }
    .content {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 0.28rem;
      color: rgba(255, 255, 255, 0.7);
      // line-height: 1.5714rem;
      text-align: left;
      margin-bottom: 0.4rem;
      cursor: pointer;
    }
  }
  .code {
    //margin-right: 1.08rem;
    margin-top: 0.44rem;
    .imgcode {
      width: 1.82rem;
      height: 1.82rem;
      border: 1px solid #fff;
    }
    .code_text {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 0.32rem;
      color: #ffffff;
      // line-height: 1.5714rem;
      padding-top: 0.2rem;
    }
  }
}
.footer_line {
  // margin-top: 3.7143rem;
  // margin: 3.7143rem 18.5714rem 0px 0px;
  // margin-bottom: 88px;
  margin-top: 0.66rem;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  .footer_line_content {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 0.24rem;
    color: #ffffff;
    line-height: 0.5rem;
    padding-bottom: 0.36rem;
    padding-top: 0.32rem;
  }
}
</style>
