import { createApp } from 'vue'
import Antd from 'ant-design-vue';
import App from './App';
import 'ant-design-vue/dist/antd.css';
import router from './router'
import "@/assets/PangMenZhengDao.ttf"

// import "@/assets/PingFangRegular.ttf"
// import "@/assets/PingFangBold.ttf"


// import DatePicker from 'ant-design-vue/es/date-picker/moment';
// import TimePicker from 'ant-design-vue/es/time-picker/moment';
// import Calendar from 'ant-design-vue/es/calendar/moment';

createApp(App).use(router).use(Antd).mount('#app')
