<template>
  <a-config-provider :locale="locale">
    <router-view></router-view>
  </a-config-provider>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");
export default {
  name: "App",
  components: {
    // HelloWorld
  },
  data() {
    return {
      locale: zhCN,
    };
  },
  created() {
    // this.detectDeviceType();
  },
  methods: {
    detectDeviceType() {
      const machineType =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
          ? "Mobile"
          : "Desktop";
      if (machineType === "Mobile") {
        console.log("isH5-移动端", this.$route);
        if (this.$route.path == "/") {
          let h5 = this.$route.path + "_h5";
          this.$router.replace(h5);
        } else {
          window.location.href = "/#" + to.fullPath.replace("_h5", "");
          let url = this.$route.path.replace("_h5", "");
          let h5 = this.$route.path + "_h5";
          this.$router.replace(h5);
        }
      } else {
        let url = this.$route.path.replace("_h5", "");
        this.$router.replace(url);
        console.log("isH5-Pc端");
      }
    },
  },
};
</script>

<style>
@font-face {
  font-family: "PangMenZhengDao";
  src: url(~@/assets/PangMenZhengDao.ttf) format("truetype");
}
@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 0.4rem!important;
    bottom: 1.96rem!important;
  }
}

@media screen and (max-width: 768px) {
  .ant-back-top {
    right: 0.4rem!important;
    bottom: 1.96rem!important;
  }
}
/*
@font-face {
  font-family: "PingFangSC";
  src: url(~@/assets/PingFangRegular.ttf) format("truetype");
} */
/* @font-face {
  font-family: "PingFang SC";
  src: url(~@/assets/PingFangBold.ttf) format("truetype");
} */
.bg-white {
  background: #fff;
}
.inbox {
  /* margin-top: 50px; */
  position: initial;
  overflow: visible;
}
.swiper-vertical > .swiper-scrollbar,
.swiper-scrollbar.swiper-scrollbar-vertical {
  opacity: 0;
}
.swiper-vertical > .swiper-pagination-bullets,
.swiper-pagination-vertical.swiper-pagination-bullets {
  left: 18.5714rem !important;
}
.swiper-pagination-bullet-active {
  position: relative;
}
.swiper-slide-active {
  opacity: 1 !important;
}
.swiper-pagination-bullet {
  position: relative;
}
.swiper-pagination-bullet {
  width: 6px !important;
  height: 6px !important;
}
.ant-back-top {
  right: 50px;
}

.swiper-pagination-bullet-active::before {
  content: "";
  border: 1px solid rgba(58, 137, 254, 0.64);
  width: 13px;
  height: 13px;
  display: inline-block;
  border-radius: 75%;
  /* display: flex; */
  position: absolute;
  left: -3.5px;
  top: -3px;
  box-sizing: border-box;
}
.tab-content1,
.tab-content2,
.tab-content3,
.tab-content4,
.tab-content5 {
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
}
.box3_content {
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
}
.box2_content {
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
}
.box1_content {
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
}
.box5_content {
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
}
.tab-content1 .tab_item:hover {
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
}
.tab-content2 .tab_item:hover {
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
}
.tab-content3 .tab_item:hover {
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
}
.tab-content4 .tab_item:hover {
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
}
.tab-content5 .tab_item:hover {
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
}
.tab-content3 .tab-left img:hover {
  background: url("../public/imgs/ems/leftBtn_hov.png") no-repeat;
}
.tab-content3 .tab-right img:hover {
  background: url("../public/imgs/ems/rightBtn_hov.png") no-repeat;
}
.swiper-button-prev {
  background: url("../public/imgs/ems/leftBtn.png") no-repeat;
  width: 4.5714rem;
  height: 4.5714rem;
  top: 116%;
  left: 41.7857rem;
}
.swiper-button-prev:after {
  content: "";
}
.swiper-button-next {
  width: 4.5714rem;
  height: 4.5714rem;
  background: url("../public/imgs/ems/rightBtn.png") no-repeat;
  top: 116%;
  right: 45.7857rem;
}
.swiper-button-next:after {
  content: "";
}
.swiper-button-prev:hover {
  background: url("../public/imgs/ems/leftBtn_hov.png") no-repeat;
}
.swiper-button-next:hover {
  background: url("../public/imgs/ems/rightBtn_hov.png") no-repeat;
}

/* ::-webkit-scrollbar {

   width: 4px;
  height: 2px;
}
::-webkit-scrollbar-thumb {

  border-radius: 8px;
  box-shadow: inset 0 0 2px #a8a8a8;
  background: #a8a8a8;
}
::-webkit-scrollbar-track {

  box-shadow: inset 0 042px #ededed;
  border-radius: 8px;
  background: #ededed;
} */

.PangMenZhengDao {
  font-family: PangMenZhengDao;
}
@font-face {
  font-family: "PangMenZhengDao";
  src: url(~@/assets/PangMenZhengDao.ttf) format("truetype");
}
body {
  font-size: 1.285714285714286rem;
}
.flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}
.align-items {
  align-items: center;
}
.flex-one {
  flex: 1;
}
.flex-end {
  justify-content: flex-end;
}
.label {
  background: #0e3871;
  height: 2.2857143rem;
  display: inline-block;
  padding: 0 0.57143rem;
  min-width: 4.286rem;
  line-height: 2.2857143rem;
  margin: 0 0.57143rem;
  color: #ffa113;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
/*.ant-picker-panels td,*/
/*.ant-picker-content thead th,*/
/*.ant-picker-header-view {*/
/*  color: #fff;*/
/*}*/
/*.ant-picker-header,*/
/*.ant-picker-panel {*/
/*  border-bottom-color: #0096d8 !important;*/
/*}*/
/*.ant-picker-range-wrapper.ant-picker-date-range-wrapper {*/
/*  border: 1px solid #0096d8;*/
/*}*/
/*.ant-picker-date-panel {*/
/*  background-color: #11214c;*/
/*}*/
/*.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {*/
/*  border: 1px solid #0096d8;*/
/*  background-color: #11214c;*/
/*}*/
/*.ant-select-item-option-active,*/
/*.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {*/
/*  background-color: #0096d8 !important;*/
/*}*/
/*.ant-select-item-option-content {*/
/*  color: #fff;*/
/*}*/
/*.ant-picker-cell-disabled {*/
/*  color: #00000040 !important;*/
/*}*/
/*.ant-picker-cell-disabled::before {*/
/*  background-color: #fff !important;*/
/*}*/
/*.ant-picker-cell-in-range .ant-picker-cell-inner {*/
/*  color: #11214c;*/
/*}*/

/** banner 标题 */
h3.bannerTitle {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 2.571rem /* 36/14 */;
  line-height: 4.2143rem /* 59/14 */;
  color: #333333;
  text-align: center;
  font-style: normal;
  margin: 6.429rem /* 90/14 */ 0 5.7857rem /* 81/14 */ 0;
}
</style>


<!--
  h5页面公用样式
-->
<style lang="less">
.white{
  color: #FFFFFF !important;
}
.black{
  color: #000 !important;
}
.blue{
  color: #0874ff !important;
}
.center{
  text-align: center !important;
}
.text-left{
  text-align: left !important;
}

/**
 生成0-100px的边距样式并转为1rem = 50px的值
 */
@i: 0;
.for-loop(@i) when (@i <= 100) {
  @v:(@i / 50); //1rem = 50px
  .mb-@{i}{ margin-bottom: ~"@{v}rem" !important;}
  .mt-@{i}{margin-top: ~"@{v}rem" !important;}
  .ml-@{i}{margin-left: ~"@{v}rem" !important;}
  .mr-@{i}{margin-right: ~"@{v}rem" !important;}
  .pt-@{i}{padding-top: ~"@{v}rem" !important;}
  .pb-@{i}{padding-bottom: ~"@{v}rem" !important;}
  .pl-@{i}{padding-left: ~"@{v}rem" !important;}
  .pr-@{i}{padding-right: ~"@{v}rem" !important;}
  .lh-@{i}{line-height: ~"@{v}rem" !important;}
  .fs-@{i}{font-size: ~"@{v}rem" !important;}
  .for-loop(@i + 1);
}
.for-loop(@i);

h2.h5_title{
  //height: 0.66rem  /* 33/50 */;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 0.48rem  /* 24/50 */;
  line-height: 0.66rem  /* 33/50 */;
  font-style: normal;
  margin-bottom: 0;
}
h3.h5_title{
  //height: 0.56rem  /* 28/50 */;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 0.4rem  /* 20/50 */;
  color: #181A24;
  line-height: 0.56rem  /* 28/50 */;
  text-align: left;
  font-style: normal;
  margin-bottom: 0;
}
h4.h5_title{
  //height: 0.4rem  /* 20/50 */;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 0.28rem  /* 14/50 */;
  color: #181A24;
  line-height: 0.4rem  /* 20/50 */;
  text-align: left;
  font-style: normal;
  margin-bottom: 0;
}
h4.h5_title16{
  //height: 0.44rem  /* 22/50 */;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 0.32rem  /* 16/50 */;
  color: #FFFFFF;
  line-height: 0.44rem  /* 22/50 */;
  text-align: left;
  font-style: normal;
  margin-bottom: 0;
}
p.h5_text12{
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 0.24rem  /* 12/50 */;
  color: #FFFFFF;
  line-height: 0.44rem  /* 22/50 */;
  text-align: left;
  font-style: normal;
  margin-bottom: 0;
}
p.h5_text14{
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.28rem  /* 14/50 */;
  color: #3F4758;
  line-height: 0.52rem  /* 26/50 */;
  text-align: left;
  font-style: normal;
  margin-bottom: 0;
}
button.h5_btn{
  outline: none;
  border: none;
  min-width: 1.76rem  /* 88/50 */;
  height: 0.64rem  /* 32/50 */;
  background: linear-gradient( 90deg, #3AB4FD 0%, #1B7DFA 100%);
  border-radius: 0.08rem  /* 4/50 */;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.24rem  /* 12/50 */;
  color: #FFFFFF;
  text-align: center;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  &::after{
    content: "";
    display: block;
    width: 0.24rem  /* 12/50 */;
    height: 0.24rem  /* 12/50 */;
    margin-left: 0.12rem  /* 6/50 */;
    background-image: url(../public/imgs/home_h5/arrow_right.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

section.h5_card{
  width: 100%;
  padding: 0.64rem  /* 32/50 */ 0.4rem  /* 20/50 */;
}
section.h5_Swiper{
  width: 100%;
  min-height: 6.4rem  /* 320/50 */;
  background: #FFFFFF;
  position: relative;
  .inbox{
    position: initial;
    height: 100%;
  }
  .swiper-slide{
    width: auto !important;
    margin: 0 0.16rem  /* 8/50 */ !important;
  }
  .swiper-button-prev,.swiper-button-next {
    background: url("../public/imgs/ems/leftBtn.png") no-repeat;
    background-size: 100% 100% !important;
    width: 0.64rem  /* 32/50 */;
    height: 0.64rem  /* 32/50 */;
    top: 108%;
    left: 2.86rem  /* 143/50 */;
    margin: 0;
  }
  .swiper-button-next {
    background: url("../public/imgs/ems/rightBtn.png") no-repeat;
    left: 3.98rem  /* 199/50 */;
  }
  .swiper-button-prev:hover,
  .swiper-button-next:hover{
    background-size: 100% 100% !important;
  }
  .swiper-button-prev:hover{
    background: url("../public/imgs/ems/leftBtn_hov.png") no-repeat;
  }
  .swiper-button-next:hover{
    background: url("../public/imgs/ems/rightBtn_hov.png") no-repeat;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after{
    content: "";
  }
}
div.h5_card_box{
  width: 100%;
  height: auto;
  padding: 0.32rem  /* 16/50 */ 0.4rem  /* 20/50 */ 0.32rem  /* 16/50 */ 1.64rem  /* 82/50 */;
  background-color: rgba(255,255,255,0.5);
  background-image: url(../public/imgs/business_h5/business_icon1.png);
  background-repeat: no-repeat;
  background-size: 0.86rem  /* 43/50 */ auto;
  background-position: 0.4rem  /* 20/50 */ center;
  box-shadow: 0rem  /* 0/50 */ 0.12rem  /* 6/50 */ 0.24rem  /* 12/50 */ 0rem  /* 0/50 */ rgba(207,215,225,0.42);
  border-radius: 0.16rem  /* 8/50 */;
  &:last-child{
    margin-bottom: 0;
  }
}

ul.h5_navList{
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  >li{
    margin: 0;
    padding-left: 0.32rem  /* 16/50 */;
    list-style: none;
    min-width: 3.2rem  /* 160/50 */;
    width: 47%;
    height: 1.04rem  /* 52/50 */;
    border-radius: 0.16rem  /* 8/50 */;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 0.32rem  /* 16/50 */;
    color: #FFFFFF7F;
    line-height: 1.04rem  /* 52/50 */;
    text-align: center;
    font-style: normal;
    border: 0.02rem  /* 1/50 */ solid transparent;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img{
      display: block;
      margin-right: 0.16rem  /* 8/50 */;
    }
    &.active{
      color: #FFFFFF;
      border-color:#1B7DFA;
    }
  }
}


ul.h5_trait-list{
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  li.trait-list_item{
    width: 100%;
    list-style: none;
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    .icon{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 0.02rem  /* 1/50 */ solid rgba(255,255,255,0.5);
      img{
        display: block;
        height: 0.4rem  /* 20/50 */;
      }
    }
    h4{
      margin: 0;
    }
    &:last-child .icon{
      border-right: none;
    }
  }
}
</style>
